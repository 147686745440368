/* 
* {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
  } */

  * {
    margin: 0;
    padding: 0;
  }

  html, body {
      
    height: 100%;
    width:100%;
    /* min-height: 100%; */
    margin: 0px;
    /* overflow: hidden; */
    /* padding-bottom: 20px; */
    padding:0px;
    overflow-x: hidden; 
    overflow-y: hidden; 
  }

  /* remove scrollbar */
  ::-webkit-scrollbar {
    width: 0px;  
    background: transparent;  
}
/* Optional: show position indicator in red */
/* ::-webkit-scrollbar-thumb {
    background: #FF0000;
} */
  
  div#root,div.mainContainer{
      /* background-color: brown; */
      position: relative;
      height: 100%;
      overflow: hidden;

  }
  div#root div.mainContainer div.container-fluid{
    /* background-color: yellow; */
    position: relative;
    height: 100%;
    overflow: hidden;

}

  div.webview{
    position: relative;
    height:100%;
  }

/* style to hide mobile related divs in web view */
  div.mobileview,nav.mobilenavbar,nav.mobfooter,div.mobloginboxalert{
    display: none;
  }
/* style to hide mobile related divs in web view */
  

 

  table.table-hover tbody:hover tr:hover td {
    background-color: #d9d9d9; 
    cursor: pointer;
}

div.hdreditfilemodal{
  font-family: 'Exo 2', sans-serif;
  /* font-weight: bolder; */
  font-size:17px;
  color:#363636;
}

div.fontclassfordiv{
  font-family: 'Exo 2', sans-serif;
  /* font-weight: bolder; */
  font-size:17px;
  color:#363636;
}

/*  ############################## style for web search bars################################### */
button.webhomesrchbtn--notused{
  width:50px;
  height:100%;
  position:absolute;
  right:0px;
  /* top:20px; */
  background-color:transparent;
  border: hidden;
  background-color: unset;
}
div.webhomesrchbtn{
  width:50px;
  height:98%;
  position: absolute;
  right:-51px;
  background-image: url('images/search-field.png');
  /* background-color: #f9f6f2; */
  top:.3px;
  background-color:transparent;
  border: none;
  /* background-color: unset; */
}
div.webhomesrchbtn:hover{
  /* background-color:transparent; */
  cursor: pointer;
}

div.webpagessrchbtn{
  width:50px;
  height:98%;
  position: absolute;
  right:25%;
  background-image: url('images/search-field.png');
  /* background-color: #f9f6f2; */
  top:-1px;
  background-color:transparent;
  border: none;
  /* background-color: unset; */
}
div.webpagessrchbtn:hover{
  /* background-color:transparent; */
  cursor: pointer;
}
/* button.webhomesrchbtn:click{
  background-color:transparent;
} */

div.webcredits{
  /* background-color: tomato; */
  height:30px;
  width: 100px;
  left:96%;
  /* bottom:2px; */
  top:67%;
  position: relative;
  /* color: #333333; */
}

a.moblogoutlink{
  font-family: 'Exo 2', sans-serif; 
  /* font-weight: bolder; */
  font-size:17px;
  /* color:#363636; */
}

div.webnavbar{
  font-family: 'Exo 2', sans-serif;
  /* font-weight: bolder; */
  font-size:17px;
  /* color:#363636; */
}

/*  ############################## end of style for web search bars################################### */

/*  ############################## style for web body 1st col################################### */
div.col1{
  position: relative;
  padding:0px;
  /* top:10px; */
  height:92%;
  background-color: #f9f6f2;
  /* background-color: red; */
  /* padding-bottom: 0px; */
  overflow: auto;
  font-family: 'Exo 2', sans-serif;
  /* font-weight: bolder; */
  font-size:17px;
  color:#363636;
}

div.col1 table.col1-table{
  position: relative;
  top:10px;
  width: 100%;;
}
tr.webfrstcolrw{
  /* background-color: tomato; */
  padding-bottom: 10px;
}
/* td.webfrscol1:active{  
  background-color: tomato;
} */
td.webfrscol1{
  width: 15%;
  /* background-color: tomato; */
}
td.webfrscol2{
  width: 10%;
  /* padding-right: ; */
}
i.webeditfrstrw{
  font-size: 20px;
  color: #1eb2a6;
}
td.webfrscol3{
  width: 10%;
}
i.webdelfrstrw{
  font-size: 20px;
  color: red;
}
a.webfrstcolprev{
  /* margin-left:30%; */
  position: relative;
  left:70%;
}
a.webfrstcolnext{
  position: relative;
  left:30%;
}
div.webfrstcolnavs{
  margin-top:50px;
  width:100%;
}

div.webhomerocol1img{
  height: 95%;
  width:160%;
  top:6.5%;
  /* background-color: red; */
  right:5px;
  border:none;
  background-color: transparent;
  /* padding-top: 100px; */
}

img.webbhomecol1imgtag{
  position: relative;
  /* top:13px; */
  margin:auto;
}

td.webeditmodaling1stcolnme{
  word-break: break-all;
  width: 45%;
}

td.webeditmodaling2ndcolqty{
  word-break: break-all;
}

div.webhomepageloader1{
  background-color: transparent;
  background-image: url('images/loaders/Croissant.svg');
  background-position: center;
  background-size: contain;
       background-repeat: no-repeat;
       /* margin-right: 3px; */
       width: 15%;
       /* position: relative; */
       /* left:10px; */
}

div.webhomepageloader2{
 background-color: transparent;
 background-image: url('images/loaders/Boba.svg');
 background-position: center;
 background-size: contain;
       background-repeat: no-repeat;
       /* margin-right: 3px; */
       width: 15%;
       /* position: relative; */
       /* left:10px; */
}

div.webhomepageloader3{
 background-color: transparent;
 background-image: url('images/loaders/Cheesecake.svg');
 background-position: center;
 background-size: contain;
 background-repeat: no-repeat;
 /* margin-right: 3px; */
 width: 15%;
 /* position: relative; */
 /* left:10px; */
}

div.webhomepageloader4{
 background-color: transparent;
 background-image: url('images/loaders/Cutlery.svg');
 background-position: center;
 background-size: contain;
 background-repeat: no-repeat;
 /* margin-right: 3px; */
 width: 15%;
 /* position: relative; */
 /* left:10px; */
}

div.webhomepageloader5{
 background-color: transparent;
 background-image: url('images/loaders/Pizza.svg');
 background-position: center;
 background-size: contain;
       background-repeat: no-repeat;
       /* margin-right: 3px; */
       width: 15%;
       /* position: relative; */
       /* left:10px; */
}

div.webhomepageloader6{
 background-color: transparent;
 background-image: url('images/loaders/saltpepper.svg');
 background-position: center;
 background-size: contain;
 background-repeat: no-repeat;
 /* margin-right: 3px; */
 width: 15%;
 /* position: relative; */
 /* left:10px; */
}

div.webhomepageloader7{
 background-color: transparent;
 background-image: url('images/loaders/Sandwich.svg');
 background-position: center;
 background-size: contain;
 background-repeat: no-repeat;
 /* margin-right: 3px; */
 width: 15%;
 /* position: relative; */
 /* left:10px; */
}

/*  ############################## end style for web body 1st col################################### */



/*  ############################## style for web body 2nd col################################### */
div.col2{
  overflow: auto;
  height: 92%;
  background-color: #d9d9d9;
  font-family: 'Exo 2', sans-serif;   
  font-size:17px;
  color:#363636;
  /* font-weight: bolder; */
}

div.websecndcolrw1{
  /* background-image: url('images/pizza1.png'); */
  height: 30%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

div.websecndcolhdr{
  /* was active */
  /* background-color: #2a6171; */
  background-color:rgba(0, 0, 0, 0.5);
  /* opacity: 0.5; */
  position:relative;
  /* height: 30%; */
  top:20%;
  margin:auto;
  /* -webkit-transform: rotate(-6deg);
  -moz-transform: rotate(-6deg);
  -o-transform: rotate(-6deg); */
  /* font-size: larger; */
  word-wrap: break-word;
  overflow: hidden;
  padding: 1%;

  /* was active */
  box-shadow:0px 0px 30px 1px #333333;  
  border-radius: 5rem;
  /* width:50%; */
}

span.websecndcolhdrspan{
  margin:auto;
  font-size: 30px;
  /* color:#f2f4fb; */
  color: #f0f0f0;
  font-weight: bold;
  /* width:100%; */
  /* padding-left: 20%; */
}
div.websecndcolhdrspan{
  margin:auto;
  font-size: 30px;
  color:#f2f4fb;
  font-weight: bold;
}

div.websecndcolrw2{
  background-color: #979797;
}

form.websecondcollnk{
  position:relative;
  width:50%;
  left:41%;
}

span.websecondcolbdge1{
  margin-top: 10px;
  font-size: 12px;
  width:20%;
}

img.websecondcolbdge1{
  /* margin-top: 10px; */
  font-size: 12px;
  width:15%;
  position: relative;
  left: 50px;
}

img.websecondcolbdge1:hover{
  cursor: pointer;
}

div.websecondcolbdge1{
  font-size: 12px;
  width:20%;
  position: relative;
  left: 60px;
}

div.websecndcolrw3{
  background-color: #d4d7dd;
}

form.websecndcolprep1{
  position:relative;
  width:50%;
  left:15%;
  padding-top: 10px;
}

div.websecndcolprep2{
  position: relative;
  left:20%;
}

input.websecndcolprep3{
  position: relative;
  top:-5px;
  width: 200px;
}

div.websecndcolrw4{
  background-color: #eae9e9;
}

span.websecndcoling1{
  position: relative;
  left:50%;
  box-shadow:0px 0px 6px 1px #333333;
  background-color: #90303d;
}

i.websecndcoladshp1{
  position: relative;
  top: 20%;
  left:30%;
  font-size: 40px;
  color: #1eb2a6;
}

button.websecndcoladshp1{
  position: relative;
  top: 20%;
  left:30%;
  /* background-color: transparent;
  border: none; */
  /* font-size: 40px; */
}

svg.websecndcoladshp1{
  position: relative;
  top: 20%;
  left:30%;
  /* font-size: 40px;
  color: #1eb2a6; */
}

svg.websecndcoladshpico1{
  position: relative;
  top: 20%;
  left:30%;
}
svg.websecndcoladshpico1:hover{
  cursor: pointer;
}

i.websecndcoladshp2{
  position: relative;
  left: 50%;
  font-size: 40px;
  color: #1eb2a6;
  /* width:2px; */
  /* height: 90px;; */
}

svg.websecndcoladshpico2{
  position: relative;
  left: 41%;
  /* font-size: 40px; */
}

svg.websecndcoladshpico2:hover{
  cursor: pointer;
}

svg.websecndcoladshp2{
  /* old */
  /* position: relative;
  left: 50%;
  font-size: 40px;
  color: #1eb2a6; */
  /* old */
  position: relative;
  left: 41%;

  /* width:2px; */
  /* height: 90px;; */
}

td.websecndcol1{
  width: 30%;
  /* background-color: tomato; */
  word-break: break-all;
}

td.websecndcol2{
  width: 20%;
  /* background-color: tomato; */
  word-break: break-all;
}

i.websecndcolhdredit{
  position: relative;
  left: 50%;
  font-size: 20px;
  color: #363636;
  /* width:2px; */
  height: 99px;
}

svg.websecndcolhdredit{
  position: relative;
  /* left: 50%; */
  /* font-size: 28px; */
  top:-50%;
  /* color: #363636; */
  /* width:2px; */
  /* height: 99px; */
  margin-bottom: 80px;
}

svg.websecndcolhdredit:hover{
  cursor: pointer;
}

div.websecndcolrw5{
  /* background-color: #f6f6f6; */
}

span.websecndcolrecpbdge{
  position: relative;
  left:45%;
  box-shadow:0px 0px 6px 1px #333333;
  background-color: #90303d;
}

/*  ############################## end style for web body 2nd col################################### */


/*  ############################## style for web body 3rd col################################### */
div.col3{
  background-color: #f9f6f2;
  /* overflow: auto;
  height:50%; */
  font-family: 'Exo 2', sans-serif;
/* font-weight: bolder; */
font-size:17px;
color:#363636;
}
div.webthrdcoladdscroll{
  overflow: auto;
  height:500px;
  margin-top:30px;
}

div.webthrdcolhdr{
  -webkit-transform: rotate(-6deg);
  -moz-transform: rotate(-6deg);
  -o-transform: rotate(-6deg);
  top:40%;
  height: 60%;
  background-color: #eb4559;
}

div.webthrdcolhdricon{
  -webkit-transform: rotate(-6deg);
  -moz-transform: rotate(-6deg);
  -o-transform: rotate(-6deg);
  top:40%;
  height: 60%;
  /* background-color: #eb4559; */
  background-color: transparent;
  border:none;
}

div.webhomerocol3img{
  -webkit-transform: rotate(-6deg);
  -moz-transform: rotate(-6deg);
  -o-transform: rotate(-6deg);
  height: 55%;
  width:50%;
  top:6.5%;
  /* background-color: red; */
  /* right:5px; */
  border:none;
  background-color: transparent;
  /* padding-top: 100px; */
}

div.webhomerocol3imgcard{
 
}

img.webhomecol3imgtag{
  -webkit-transform: rotate(-6deg);
  -moz-transform: rotate(-6deg);
  -o-transform: rotate(-6deg);
  position: relative;
  top:20px;
  left:30%;
  /* left:180px; */
  /* left:120px; */
  /* height:70%; */
  width: 30%;
}

div.webthrdcolhdrbdy{
  padding-left: 25%;
  padding-top: 2%;
}

i.webthrdcoladdshp1{
  font-size: 20px;
  color: red;
}

a.webthrdcoladdallshp1{
  position:relative;
  left:40%;
}

i.webthrdcoladdshpico1{
  font-size: 80px;
  color: #1eb2a6
}

svg.webthrdcoladdshpico1{
  position:relative;
  left:40%;
  font-size: 80px;
  /* color: #1eb2a6 */
}

svg.webthrdcoladdshpico1:hover{
  cursor: pointer;
}

td.web3rdcolshopcol1{
  word-break: break-all;
}

/*  ############################## end style for web body 3rd col################################### */



/*  ##############################style for web navbar################################### */
form.navbarsearchform{
  margin: auto auto auto 15%;
  width: 80%;
  background-color: transparent;
  font-family: 'Exo 2', sans-serif;
  /* font-weight: bolder; */
  font-size:17px;
  color:#363636;
}

input.searchfield{
  width: 70%; 
  box-shadow:0px 0px 30px 1px #333333;
  /* background: url("images/search-field.png")  right     no-repeat; */
  height:45px;
  background-color: white;
}

span.webnavbarhdrbrand{
  font-family: 'Exo 2', sans-serif;
/* font-weight: bolder; */
/* font-size:17px; */
/* color:#363636; */
}

span.webnavbarhdrbrand:hover{
  cursor: pointer;
  
}

/* a.createnewitem:focus { */
  /* outline: none !important;
  outline-offset: none !important;
  background-color: red!important; */
  /* color:rgba(255,255,255,.5) !important; */
  
/* } */
/*  ##############################style for web navbar################################### */


/*  ##############################style for web create modal################################### */
  div.webcreatemodalhdr{
    background-color: #45454d;
    color:#eae9e9;
    font-family: 'Exo 2', sans-serif; 
      /* font-weight: bolder; */
      font-size:17px;
      /* color:#363636; */
  }

  div.webcreatefirstrow{
    background-color: #d4d7dd;
  }

  form.webcreatefirstform{
    position:relative;
    top:5%;
    font-family: 'Exo 2', sans-serif;
    /* font-weight: bolder; */
    font-size:17px;
    color:#363636;
  }
  div.webcreatesecndtrow{
    background-color: #d4d7dd;
  }
  div.webcreatesecndform{
    position:relative;
    top:5%;
  }

  div.webcreatethrdtrow{
    background-color: #d4d7dd;
  }

  div.webcreatefrthrow{
    background-color: #eae9e9;
  }
  span.webcreateinghdr{
    position: relative;
    left:35%;
    box-shadow:0px 0px 6px 1px #333333;
    background-color: #90303d;
  }
  .webcreateaddingbtn{
    position: relative;
    top: 20%;
    left:20%;
    font-size: 40px;
    color: #1eb2a6;
  }

  svg.webcreateaddingbtn{
    position: relative;
    top: 20%;
    left:20%;
  }

  .webdelingrow{
    position: relative;
    left: 10%;
    font-size: 30px;
    color: #d63447;
  }

  .webdelingrow:hover{
    cursor: pointer;
  }

  div.webcreateffthrow{
    background-color: #f6f6f6;
  }

  span.webcreaterecphdr{
    position: relative;
    left:34%;
    box-shadow:0px 0px 6px 1px #333333;
    background-color: #90303d;
  }

  button.webCreateDraftSave{
    left:20px;
    position: absolute;
  }

  button.webCreateDraftload{
    left: 70px;
    position: absolute;
  }

/*  ##############################style for web create modal################################### */

  /*  ##############################style for web landing page################################### */

  div.weblndngmaincol{
    background-color: #f9f6f2;
    font-family: 'Exo 2', sans-serif;
    /* font-weight: bolder; */
    font-size:17px;
    color:#363636;

    background-image: url('images/web_lndng_bck2.svg');
    /* background-position-x: 70%; */
    background-position-y: 90%;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: 100px; */
    background-blend-mode: overlay;
   }

   div.weblndnsearchbar{
    background-color:transparent;
    position:relative;
    width:90%;
    height:20%;margin:auto;top:30%;padding:20px;
   }

   input.webmainsrchfrm{
    box-shadow:0px 0px 30px 1px #333333;
    /* background: url("images/search-field.png")  right     no-repeat; */
    height:50px;
    /* background-position: right;
    background-repeat: no-repeat; */
    background-color: transparent;
   }

   

   /*  ##############################end style for mobile landing page################################### */


/*  ############################################################################################################################### */
/*  #################################################style for mobile########################################################### */
  @media screen and (max-width: 800px) {

    div.webview,nav.webnavbar,div.editview,nav.editnav,div.webloginboxalert{
      display: none;
    }
    div.mobileview,nav.mobilenavbar,nav.mobfooter,div.mobloginboxalert{
      display: flex;
      /* height: 40px; */
    }
    div.mobileview,div.editview{
      position: relative;
      height:100%;
    }

    

    .nav-item{
      text-align: right;
      /* align-items: center;
      display: flex; */
    }

    div.mobcredits{
      /* background-color: tomato; */
      /* height:30px; */
      width: 100px;
      left:298%;
      /* bottom:2px; */
      /* top:67%; */
      padding-left: 100px;
      position: relative;
      /* color:white; */
      font-size: 11px;
    }

    span.mobcreateinghdr{
      position: relative;
      left:31%;
      box-shadow:0px 0px 6px 1px #333333;
      background-color: #90303d;
    }
  
    div.mobcreateaddingbtncol{
      padding:1px;
    }  
  
  .mobcreateaddingbtn{
      position: relative;
      top: 10%;
      /* left:1%; */
      font-size: 40px;
      color: #1eb2a6;
    }

    span.mobcreaterecphdr{
      position: relative;
      left:32%;
      box-shadow:0px 0px 6px 1px #333333;
      background-color: #90303d;
    }

    /*  ##############################style for mobile navbar################################### */
    
    form.mobnavbarsearchform{
      margin: auto;
      width: 60%;
      background-color: transparent;
    }
    
    input.mobsearchfield{
      /* width: 70%;  */
      box-shadow:0px 0px 30px 1px #333333;
      /* background: url("images/search-field.png")  right     no-repeat; */
      height:40px;
    background-color: azure;
    font-family: 'Exo 2', sans-serif; 
      /* font-weight: bolder; */
      font-size:17px;
      color:#363636;
    }

    form.mobdetlhdrfrm{
      margin-left:100px;
      width: 30%;
    }

    i.mobdetlhdrico1{
      position: relative;
      top: 10%;
      left:10%;
      font-size: 30px;
      color: #1eb2a6;
    }

    svg.mobdetlhdrico1{
      position: relative;
      /* top: 5%; */
      left:10%;
    }

    span.mobilenavbarhdrbrand:hover{
      cursor: pointer;
    }

    a.moblogoutlink{
      font-family: 'Exo 2', sans-serif; 
      /* font-weight: bolder; */
      font-size:17px;
      /* color:#363636; */
    }

    /*  ##############################style for mobile navbar################################### */

    .img-circular{
      width: 30px;
      height: 30px;
      background-image: url('images/pizza1.png');
      background-size: cover;
      display: block;
      border-radius: 100px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
     }

     /* ###################################### style for mobile create modal ################# */

     div.mobcreatemodalhdr{
      background-color: #45454d;
      color:#eae9e9;
      font-family: 'Exo 2', sans-serif; 
        /* font-weight: bolder; */
        font-size:17px;
        /* color:#363636; */
    }

    div.mobcreatemodalmainclass{
      font-family: 'Exo 2', sans-serif;
      /* font-weight: bolder; */
      font-size:17px;
      color:#363636;
    }

    button.mobCreateDraftSave{
      /* left:-15%; */
      left:10px;
      position: absolute;
    }

    button.mobCreateDraftload{
      /* left: -18%; */
      left:50px;
      position: absolute;
    }

    /* ######################################end  style for mobile create modal ################# */


     /*  ##############################style for mobile homepage################################### */
     div.mobhomecol1{
      /* top:10px; */
      overflow: scroll;
      height:85%;
      padding-bottom: 10px;
      padding-top: 10px;
      /* background-blend-mode: overlay; */
      /* background-image: url('images/mob_list_back_1.png'); */
      /* background-size: contain; */
      /* background-repeat: no-repeat;   */
      /* backgro     */
      /* background-position-y: top; */
      
      /* background-position-x: 80%; */
     }

     div.mobhomepageloader1{
       background-color: transparent;
       background-image: url('images/loaders/Croissant.svg');
       background-position: center;
       background-size: contain;
       background-repeat: no-repeat;
       /* margin-right: 3px; */
       width: 15%;
       /* position: relative; */
       /* left:10px; */
     }

     div.mobhomepageloader2{
      background-color: transparent;
      background-image: url('images/loaders/Boba.svg');
      background-position: center;
      background-size: contain;
       background-repeat: no-repeat;
       /* margin-right: 3px; */
       width: 15%;
    }

    div.mobhomepageloader3{
      background-color: transparent;
      background-image: url('images/loaders/Cheesecake.svg');
      background-position: center;
      background-size: contain;
       background-repeat: no-repeat;
       /* margin-right: 3px; */
       width: 15%;
    }

    div.mobhomepageloader4{
      background-color: transparent;
      background-image: url('images/loaders/Cutlery.svg');
      background-position: center;
      background-size: contain;
       background-repeat: no-repeat;
       /* margin-right: 3px; */
       width: 15%;
    }

    div.mobhomepageloader5{
      background-color: transparent;
      background-image: url('images/loaders/Pizza.svg');
      background-position: center;
      background-size: contain;
       background-repeat: no-repeat;
       /* margin-right: 3px; */
       width: 15%;
    }

    div.mobhomepageloader6{
      background-color: transparent;
      background-image: url('images/loaders/saltpepper.svg');
      background-position: center;
      background-size: contain;
       background-repeat: no-repeat;
       /* margin-right: 3px; */
       width: 15%;
    }

    div.mobhomepageloader7{
      background-color: transparent;
      background-image: url('images/loaders/Sandwich.svg');
      background-position: center;
      background-size: contain;
       background-repeat: no-repeat;
       /* margin-right: 3px; */
       width: 15%;
    }

     div.mobhomerw1{
      display:flex;
      /* height: 10%; */
      margin-bottom: 10px;
     }

     div.mobhomerocol1{
      /* background-image: url('images/recipe1.png'); */
      background-size:cover;
      background-repeat: no-repeat;
      border-radius: 1000px;
     }

     div.mobhomerocol1img{
       height: 95%;
       width:160%;
       top:6.5%;
       /* background-color: red; */
       right:5px;
       border:none;
       /* padding-top: 100px; */
     }

     img.mobhomecol1imgtag{
       position: relative;
       /* top:13px; */
       margin:auto;
     }

     div.mobhomerocol2{
      margin-top:6px;
      margin-right:0px;
      padding:0px;
      box-shadow:0px 0px 6px 1px #333333;
     }

     div.mobhomerocol2clicked{
      margin-top:6px;
      margin-right:0px;
      padding:0px;
      /* box-shadow:0px 0px 6px 1px #333333; */
     }

     a.mobhomerocol3{
      position: relative;
      left:0px;
      top:25%;
      margin-left: -20px;
     }

     i.mobhomerocol3ico{
      font-size: 30px;
      color: red;
      left:0px;
     }

     svg.mobhomerocol3{
      position: relative;
      left:0px;
      top:25%;
      margin-left: -20px;
     }

     div.mobhomerocol3{
      position: relative;
      left:0px;
      top:25%;
      margin-left: -20px;
     }

     /*  ##############################end style for mobile homepage################################### */



     /*  ##############################style for mobile edit page################################### */
     div.mobeditmaincol{
      overflow: scroll;
      height:87%;
      font-family: 'Exo 2', sans-serif; 
      /* font-weight: bolder; */
      font-size:17px;
      color:#363636;
     }

     div.mobeditrw1{
      background-color: #d4d7dd;
     }

     form.mobeditrw1frm1{
      position:relative;
      top:5%;
     }

     div.mobeditrw2{
      background-color: #d4d7dd;
     }

     form.mobeditrw1frm2{
      position:relative;
      top:5%;
     }

     div.mobeditrw2n{
      background-color: #d4d7dd;
     }

     div.mobeditrw3{
      background-color: #eae9e9;
     }

     div.mobeditrw3col1{
      width:70%;
     }

     span.mobeditinghdr{
      position: relative;
      left:40%;
      box-shadow:0px 0px 6px 1px #333333;
      background-color: #90303d;
     }

     td.mobeditingrwcol1nme{
      word-break: break-all;
      position: relative;
      width:60%;
     }

     td.mobeditingrwcol2qty{
      word-break: break-all;
      position: relative;
      width:40%;
    }

     div.mobeditrw3col2{
      width:30%;
     }

     i.mobeditadingrw1{
      position: relative;
      top: 20%;
      left:55%;
      font-size: 35px;
      color: #1eb2a6;
     }

     svg.mobeditadingrwico11{
      position: relative;
      top: 20%;
      left:55%;
      /* font-size: 35px;
      color: #1eb2a6; */
     }

     button.mobeditadingrwico11{
      position: relative;
      top: 20%;
      left:49%;
      /* font-size: 35px;
      color: #1eb2a6; */
     }

     i.mobedtwebdelingrow{
      position: relative;
      left: 10%;
      font-size: 30px;
      color: #d63447;
     }

     svg.mobedtwebdelingicorow{
      position: relative;
      left: 10%;
      /* font-size: 30px; */
      color: #d63447;
     }

     div.mobeditrw4{
      background-color: #f6f6f6;
     }

     span.mobedtrecphdr{
      position: relative;
      left:32%;
      box-shadow:0px 0px 6px 1px #333333;
      background-color: #90303d;
     }

     button.mobEditDraftSave{
      /* left:-15%; */
      /* left:10px; */
      /* position: absolute; */
    }

    button.mobEditDraftload{
      /* left: -18%; */
      /* left:50px; */
      /* position: absolute; */
    }


     /*  ##############################end style for mobile edit page################################### */



     /*  ##############################style for mobile detail page################################### */
     div.mobdetl{
      overflow: scroll;
      height:89%;
      background-color: #d9d9d9;
      font-family: 'Exo 2', sans-serif; 
      /* font-weight: bolder; */
      font-size:17px;
      color:#363636;
     }

     div.mobdetlrw1{
      height: 120px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
     }

     div.mobedtrw1hdr{
      /* background-color: #2a6171; */
      background-color:rgba(0, 0, 0, 0.5);
      position:relative;
      /* height: 30%; */
      top:20%;
      margin:auto;
      font-size: larger;
      word-wrap: break-word;
      overflow: hidden;
      padding-left: 2%;
      padding-right: 2%;
      box-shadow:0px 0px 30px 1px #333333;
      border-radius: 5rem;
     }

     span.mobedtrw1hdrspan{
      margin:auto;
      font-size: 20px;
      /* color:#f2f4fb; */
      color: #f0f0f0;
      font-weight: bold;
      word-break: break-all;
      padding:5px;
      /* padding-bottom: 200px; */
     }

     div.mobdetlrw2{
      background-color: #979797;
     }

     form.mobdetlrw2frm{
      position:relative;
      width:50%;
      left:25%;
      /* top:15%; */
     }

     span.mobdetlrw2bdge{
      /* margin-top: 10px; */
     }

     img.mobdetlrw2bdge{
      /* margin-top: 10px; */
      position: relative;
      width:50px;
      /* margin-bottom: 10px; */
      margin:auto;
      left: 50px;
      /* text-shadow: black; */
     }

     div.mobdetlrw2bdge{
      /* margin-top: 10px; */
      position: relative;
      width:50px;
      /* margin-bottom: 10px; */
      margin:auto;
      left: 50px;
      /* text-shadow: black; */
     }

     div.mobdetlrw3{
      background-color: #d4d7dd;
     }

     form.mobdetlrw3frm{
      position:relative;
      width:50%;
      left:10%;
      padding-top: 10px;
     }

     div.mobdetlrw3prp1{
      position: relative;
      left:20%;
     }

     input.mobdetlrw3tme1{
      position: relative;
      top:-5px;
      width:182px;
     }

     div.mobdetlrw4{
      background-color: #eae9e9;
     }

     span.mobdetlinghdr{
      position: relative;
      left:50%;
      box-shadow:0px 0px 6px 1px #333333;
      background-color: #90303d;
     }

     i.mobdetladalshp1{
      position: relative;
      top: 5%;
      left:60%;
      font-size: 40px;
      color: #1eb2a6;
      width:50px;
     }

     svg.mobdetladalshpico1{
      position: relative;
      top: 5%;
      left:60%;
      /* font-size: 40px;
      color: #1eb2a6;
      width:50px; */
     }

     svg.mobdetladalshp1{
      position: relative;
      top: 5%;
      left:60%;
      /* font-size: 40px;
      color: #1eb2a6;
      width:50px; */
     }

     td.mobsecndcol1{
      width: 50%;
      /* background-color: tomato; */
      word-break: break-all;
    }
    
    td.mobsecndcol2{
      width: 40%;
      /* background-color: tomato; */
      word-break: break-all;
    }

     i.mobdetladrwshp1{
      position: relative;
      left: 20%;
      font-size: 35px;
      color: #1eb2a6;
     }

     svg.mobdetladrwshpico2{
      position: relative;
      /* left: 20%; */
      /* font-size: 35px;
      color: #1eb2a6; */
     }

     svg.mobdetladrwshp1{
      position: relative;
      /* left: 20%;
      font-size: 35px;
      color: #1eb2a6; */
     }

     div.mobdetlrw5{
      background-color: #f6f6f6;
     }

     span.mobdetrecphdr{
      position: relative;
      margin-left:40%;
      box-shadow:0px 0px 6px 1px #333333;
      background-color: #90303d;
     }

     i.mobecndcolhdredit{
      position: relative;
      left: 50%;
      font-size: 15px;
      color: #363636;
      /* width:2px; */
      height: 50px;
    }

    svg.mobecndcolhdredit{
      position: relative;
      /* left: 50%; */
      top:-50%;
      font-size: 15px;
      color: #363636;
      /* width:2px; */
      /* height: 10px; */
      margin-bottom: 40px;
    }

    div.mobcreatemodalhdr{
      background-color: #45454d;
      color:#eae9e9;
      font-family: 'Exo 2', sans-serif; 
        /* font-weight: bolder; */
        font-size:17px;
        /* color:#363636; */
    }

    tr.mobshoprowclass1{
      font-family: 'Exo 2', sans-serif; 
        /* font-weight: bolder; */
        font-size:17px;
        color:#363636;
    }

     /*  ##############################end style for mobile detail page################################### */

     /*  ##############################style for mobile landing page################################### */

     div.moblndngmaincol{
      background-color: #f9f6f2;
      background-image: url('images/home_back_2.svg');
      /* background-position-x: 70%; */
      background-position-y: 70%;
      background-repeat: no-repeat;
      background-size: cover;
      /* background-size: 100px; */
      background-blend-mode: overlay;
      /* opacity: 0.2; */
     }

     div.moblndnsearchbar{
      background-color:transparent;
      position:relative;
      width:98%;
      height:20%;margin:auto;top:30%;padding:20px;
     }

     input.mobmainsrchfrm{
      box-shadow:0px 0px 30px 1px #333333;
      /* background: url("images/search-field.png")  right     no-repeat; */
      height:50px;
      left:-15px;
      /* background-position: right;
      background-repeat: no-repeat; */
      background-color: transparent;
      font-family: 'Exo 2', sans-serif; 
      /* font-weight: bolder; */
      font-size:17px;
      color:#363636;
     }

     div.mobhomesrchbtn{
      width:50px;
      height:98%;
      position: absolute;
      right:-33px;
      background-image: url('images/search-field.png');
      /* background-color: #f9f6f2; */
      top:.3px;
      background-color:transparent;
      border: none;
      /* background-color: unset; */
    }
    div.mobhomesrchbtn:hover{
      /* background-color:transparent; */
      cursor: pointer;
    }

    div.mobhdrsrchbtn{
      width:50px;
      height:110%;
      position: absolute;
      right:11px;
      background-image: url('images/search-field.png');
      /* background-color: #f9f6f2; */
      top:-5px;
      background-color:transparent;
      border: none;
      /* background-color: unset; */
    }
    div.mobhdrsrchbtn:hover{
      /* background-color:transparent; */
      cursor: pointer;
    }

    

     /*  ##############################end style for mobile landing page################################### */


     /*  ##############################style for mobile shopping list pop up page################################### */
     i.mobshopmodalico{
      font-size: 20px;
      color: red;
     }

     i.mobshopmodalcncel{
      font-size: 40px;
      margin:0px;
      color:#45454d
     }

     svg.mobshopmodalcncel{
      /* font-size: 40px; */
      margin:0px;
      color:#45454d
     }

     td.mobshopmodalTablecol1{
       word-break: break-all;
     }

     /*  ##############################end style for mobile shopping list pop up page################################### */


     

  }
  /*  ############################################################################################################################### */
/*  #################################################end style for mobile########################################################### */


