* {
    margin: 0;
    padding: 0;
  }
  
  html, body {
    height: 100%;
    width:100%;
    /* min-height: 100%; */
    margin: 0px;
    /* overflow: hidden; */
    /* padding-bottom: 20px; */
    padding:0px;
    overflow-x: hidden; 
  }
  
  div.loginbox{
    display:none
  }
  div.logincontainer{
    background-color: #393e46;
    height:100%;
    position: relative;
    top:0px;
    margin-bottom: 0px;
    margin-left:0px;
    margin-right: 0px;
    padding:0;
    width: 100%;
    max-width: 100%;
    background-image: url('../../images/web_login_main_back_3.svg');
    background-blend-mode: overlay;
    background-position-y: 40%;
    background-position-x: 20%;
    /* opacity: 0.2; */
    /* filter: alpha(opacity=20); */
  }
  
  div.logincontainer div.loginbox,div.webloginbox{
    background-color: #d4d7dd;
    position: relative;
    height: 35%;
    top:30%;
    width:20%;
    left:38%;
    padding:2%;
    background-image: url('../../images/web_login_box3.svg');
    /* background-blend-mode: ; */
    background-position-y: 98%;
    /* background-position-x: 90%; */
    /* background-size: 30%; */
    background-repeat: no-repeat;
    /* margin-left:30% */
    /* opacity:1 !important;  */
  }
  div#errorlogin{
  position: relative;
    /* height: 30%; */
    top:30%;
    width:20%;
    left:38%;
    padding:2%
  }
  
  @media screen and (max-width: 800px) {

    div.loginbox{
      display:block
    }

    div.webloginbox{
      display:none
    }
  
    div.logincontainer{
        width: 100%;
        max-width: 100%;
        right:0;
        margin-right: 0;
        background-image: url('../../images/mob_login_back7.svg');
        background-blend-mode: overlay;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position-y: 40%;
    /* background-position-x: 20%; */
    }
  
    div.logincontainer div.loginbox,div.webloginbox {
      width: 100%;
      left:0;
      height: 28%;
      background-image: url('../../images/mob_login_box2.svg');
    /* background-blend-mode: ; */
    background-position-y: 98%;
    /* background-position-x: 90%; */
    /* background-size: 30%; */
    background-repeat: no-repeat;
    background-size: cover;
      
      /* height: 92%; */
    }
    div#errorlogin{
      width: 100%;
      left:0;
      /* height: 28%; */
    }
  
  }

  
  
  div#errorlogin{
  display:none
  }
  
  

  @media screen and (max-height: 600px) {
  
    /* div.container{
      background-color: blue;
        /* width: 100%;
        max-width: 100%;
        right:0;
        margin-right: 0; */
    /* }  */
    
    /* div.container div.loginbox {
      /* width: 100%;
      left:0;
      height: 28%; */
      /* height: 92%; */
    /* } */
    div.logincontainer div.loginbox,div.webloginbox{
      /* background-color: red; */
      /* position: relative; */
      height: 40%;
      /* top:30%;
      width:20%;
      left:38%;
      padding:2% */
      /* margin-left:30% */
    }

    /* div.loginbox{
      background-color: blue;
    } */
    
    }